/* eslint-disable default-case */
// @flow

import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import PlayIcon from '../../../../static/svg/white-play.svg';
import Breakpoint from '../../../utils/breakpoint';
import Title from '../../BannerShared/Title';
import AppButton from '../../Button';

type Props = {
  handleVideo: () => void,
};

const HeroFranchise = ({ handleVideo }) => {
  const { t } = useTranslation();

  return (
    <div className="banner banner-hero-franchise">
      <div className="container">
        <Fade>
          <Row type="flex" align="middle">
            <Col xs={24} sm={24} md={16} lg={14}>
              <Fade>
                <section>
                  <Title title={t('home_franchise_label')} isTagline />
                  <Title title={t(`home_franchise_title`)} />
                  <p>{t(`home_franchise_body`)}</p>

                  <div className="buttons-container">
                    <AppButton type="button" transform="lowercase" onClick={() => handleVideo()}>
                      <img src={PlayIcon} alt="Play" style={{ marginRight: '10px' }} />
                      <span>{t('home_splash_watchvideo')}</span>
                    </AppButton>
                  </div>
                </section>
              </Fade>
            </Col>
          </Row>
        </Fade>
      </div>
    </div>
  );
};

HeroFranchise.propTypes = {
  t: PropTypes.func,
};

export default HeroFranchise;
