import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import GreyChevron from '../../../../static/svg/grey-chevron-left.svg';
import investmentImg from '../../../images/banners/investment.jpg';
import Breakpoint from '../../../utils/breakpoint';
import Title from '../../BannerShared/Title';

const TheInvestment = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-theinvestment">
      <div className="container">
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={12}>
            <Breakpoint max="lg">
              <div className="box-image-container">
                <img src={GreyChevron} alt="grey chevron" className="left-chevron" />
                <div className="grey-box" />
                <img src={investmentImg} alt="The Coaching" />
              </div>
            </Breakpoint>
          </Col>
        </Row>

        <Row type="flex">
          <Col xs={24} sm={24} md={22} lg={12}>
            <section>
              <Fade>
                <Title title={t('franchise_investment_label')} isTagline />
                <Title title={t(`franchise_investment_title`)} />
                <p>{t(`franchise_investment_body`)}</p>
                <ul className="red-tick-list">
                  <li>
                    <strong>{t('franchise_investment_list1_a')}</strong> {t('franchise_investment_list1_b')}
                  </li>
                  <li>
                    <strong>{t('franchise_investment_list2_a')}</strong> {t('franchise_investment_list2_b')}
                  </li>
                  <li>
                    <strong>{t('franchise_investment_list3_a')}</strong> {t('franchise_investment_list3_b')}
                  </li>
                  <li>
                    <strong>{t('franchise_investment_list4_a')}</strong> {t('franchise_investment_list4_b')}
                  </li>
                </ul>
              </Fade>
            </section>
          </Col>

          <Breakpoint min="lg">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Fade>
                <div className="box-image-container">
                  <img src={GreyChevron} alt="grey chevron" className="left-chevron" />
                  <div className="grey-box" />
                  <img src={investmentImg} alt="The Coaching" />
                </div>
              </Fade>
            </Col>
          </Breakpoint>
        </Row>
      </div>
    </div>
  );
};

TheInvestment.propTypes = {
  t: PropTypes.func,
};

export default TheInvestment;
