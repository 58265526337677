import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import USP1 from '../../../images/banners/whyritual_1.jpg';
import USP2 from '../../../images/banners/whyritual_2.jpg';
import USP3 from '../../../images/banners/whyritual_3.jpg';
import Title from '../../BannerShared/Title';

const TheWorkoutWhyRitual = () => {
  const { t } = useTranslation();

  return (
    <div className="banner banner-whyritual">
      <div className="container">
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={18} xl={16}>
            <Fade>
              <section style={{ textAlign: 'center' }}>
                <Title title={t('franchise_whyritual_label')} isTagline isCenter />
                <Title title={t('franchise_whyritual_title')} style={{ marginBottom: '50px' }} />
              </section>
            </Fade>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="banner-whyritual__box">
              <img src={USP1} alt="USP1" />
              <div className="banner-whyritual__box_inner">
                <h3>{t('franchise_whyritual_usp1_title')}</h3>
                <p>{t('franchise_whyritual_usp1_body')}</p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="banner-whyritual__box">
              <img src={USP2} alt="USP2" />
              <div className="banner-whyritual__box_inner">
                <h3>{t('franchise_whyritual_usp2_title')}</h3>
                <p>{t('franchise_whyritual_usp2_body')}</p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="banner-whyritual__box">
              <img src={USP3} alt="USP3" />
              <div className="banner-whyritual__box_inner">
                <h3>{t('franchise_whyritual_usp3_title')}</h3>
                <p>{t('franchise_whyritual_usp3_body')}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

TheWorkoutWhyRitual.propTypes = {
  t: PropTypes.func,
};

export default TheWorkoutWhyRitual;
