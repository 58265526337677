import './index.less';

import React, { useState } from 'react';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import { useTranslation } from 'react-i18next';

import IconMarker from '../../images/map.png';
import { checkFlagLarge, checkFlagName, inCountry } from '../../utils/flag';

const FranchiseMap = (props) => {
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState('sg');

  const getFranchiseLocationDetail = () => {
    switch (selectedLocation) {
      case 'sg':
        return (
          <>
            <div className="map-title">
              <img src={checkFlagLarge(selectedLocation)} />
              <h1>Singapore</h1>
            </div>
            <p>5 {t('franchise_globalexpansion_companyownedunits', { count: 5 })}</p>
            <p>2 {t('franchise_globalexpansion_unitsdue')}</p>
          </>
        );
      case 'es':
        return (
          <>
            {' '}
            <div className="map-title">
              <img src={checkFlagLarge(selectedLocation)} />
              <h1>Madrid</h1>
            </div>
            <p>2 {t('franchise_globalexpansion_companyownedunits', { count: 2 })}</p>
            <p>8 {t('franchise_globalexpansion_franchisedunitsindev')}</p>
          </>
        );
      case 'ch':
        return (
          <>
            {' '}
            <div className="map-title">
              <img src={checkFlagLarge(selectedLocation)} />
              <h1>Zürich</h1>
            </div>
            <p>1 {t('franchise_globalexpansion_companyownedunits', { count: 1 })}</p>
            <p>2 {t('franchise_globalexpansion_franchisedunitsindev')}</p>
          </>
        );
      case 'cn':
        return (
          <>
            {' '}
            <div className="map-title">
              <img src={checkFlagLarge(selectedLocation)} />
              <h1>Shanghai</h1>
            </div>
            <p>20 {t('franchise_globalexpansion_franchisedunitsindev')}</p>
          </>
        );
      case 'br':
        return (
          <>
            {' '}
            <div className="map-title">
              <img src={checkFlagLarge(selectedLocation)} />
              <h1>São Paulo</h1>
            </div>
            <p>2 {t('franchise_globalexpansion_companyownedunits', { count: 2 })}</p>
            <p>2 {t('franchise_globalexpansion_franchisedunitsindev')}</p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="franchise-map-wrapper">
        <img src={IconMarker} className="franchise-map-img" alt="map" />
        <div
          onClick={() => {
            setSelectedLocation('sg');
          }}
          className={`franchise-map-dot singapore ${selectedLocation === 'sg' && 'active'}`}
        />
        <div
          onClick={() => {
            setSelectedLocation('es');
          }}
          className={`franchise-map-dot madrid ${selectedLocation === 'es' && 'active'}`}
        />
        <div
          onClick={() => {
            setSelectedLocation('ch');
          }}
          className={`franchise-map-dot zurich ${selectedLocation === 'ch' && 'active'}`}
        />
        <div
          onClick={() => {
            setSelectedLocation('cn');
          }}
          className={`franchise-map-dot shanghai ${selectedLocation === 'cn' && 'active'}`}
        />
        <div
          onClick={() => {
            setSelectedLocation('br');
          }}
          className={`franchise-map-dot saopaulo ${selectedLocation === 'br' && 'active'}`}
        />
      </div>
      <div className={`franchise-map-detail map-${selectedLocation}`}>{getFranchiseLocationDetail()}</div>
    </div>
  );
};

export default FranchiseMap;
