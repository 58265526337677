import '../banners.less';
import './index.less';

import { Link } from '@tvai/gatsby-plugin-i18next';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import incentiveprogramImg from '../../../../static/svg/incentiveprogram.svg';
import Title from '../../BannerShared/Title';

const IncentiveProgram = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-incentiveProgram">
      <div className="container">
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={18} xl={16}>
            <Fade>
              <section style={{ textAlign: 'center' }}>
                <img src={incentiveprogramImg} alt="incentiveprogram" />
                <Title title={t('franchise_incentive_label')} isTagline isCenter />
                <Title title={t(`franchise_incentive_title`)} />
                <div dangerouslySetInnerHTML={{ __html: t(`franchise_incentive_body`) }} />
              </section>
            </Fade>
          </Col>
        </Row>
      </div>
    </div>
  );
};

IncentiveProgram.propTypes = {
  t: PropTypes.func,
};

export default IncentiveProgram;
