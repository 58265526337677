import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import gallery1 from '../../../images/banners/facility1.jpg';
import gallery2 from '../../../images/banners/facility2.jpg';
import gallery3 from '../../../images/banners/facility3.jpg';
import gallery4 from '../../../images/banners/facility4.jpg';
import gallery5 from '../../../images/banners/facility5.jpg';
import gallery6 from '../../../images/banners/facility6.jpg';
import gallery7 from '../../../images/banners/facility7.jpg';
import gallery8 from '../../../images/banners/facility8.jpg';
import thefacilityImg from '../../../images/banners/thefacility.jpg';
import Title from '../../BannerShared/Title';

const TheFacility = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-thefacility">
      <div className="container">
        <Row type="flex" align="middle">
          <Col xs={24} sm={0} md={0} lg={0}>
            <Fade>
              <img src={thefacilityImg} alt="The Facility" />
            </Fade>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12}>
            <Fade>
              <section
                style={{
                  backgroundColor: '#fff',
                  position: 'relative',
                  zIndex: 99999,
                  padding: '40px 20px 40px 0px',
                }}
              >
                <Title title={t('franchise_thefacility_label')} isTagline />
                <Title title={t(`franchise_thefacility_title`)} />
                <p>{t(`franchise_thefacility_body`)}</p>
                <small style={{ fontStyle: 'italic' }}>{t(`franchise_thefacility_footer`)}</small>
              </section>
            </Fade>
          </Col>

          <Col xs={0} sm={24} md={8} lg={12}>
            <Fade>
              <img src={thefacilityImg} alt="The Facility" />
            </Fade>
          </Col>
        </Row>
        <Row gutter={20} style={{ marginTop: '60px', marginBottom: '20px' }}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={gallery1} alt="facility-1" className="facility-img" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={gallery2} alt="facility-2" className="facility-img" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={gallery3} alt="facility-3" className="facility-img" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={gallery4} alt="facility-4" className="facility-img" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={gallery5} alt="facility-5" className="facility-img" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={gallery6} alt="facility-6" className="facility-img" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={gallery7} alt="facility-7" className="facility-img" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={gallery8} alt="facility-8" className="facility-img" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

TheFacility.propTypes = {
  t: PropTypes.func,
};

export default TheFacility;
