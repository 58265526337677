import './index.less';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const FAQAccordion = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="accordion">
      <Collapse
        expandIconPosition="right"
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
        bordered={false}
        ghost
      >
        {data &&
          data.map((faqItem, index) => (
            <Panel
              header={t(`${faqItem.titleKey}`)}
              key={index}
              style={{ backgroundColor: '#F1F1F1', marginBottom: '10px', borderRadius: '10px' }}
            >
              <div dangerouslySetInnerHTML={{ __html: t(`${faqItem.bodyKey}`) }} />
            </Panel>
          ))}
      </Collapse>
    </div>
  );
};

FAQAccordion.propTypes = {
  t: PropTypes.func,
  data: PropTypes.array.isRequired,
};

export default FAQAccordion;
