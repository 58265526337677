import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Link } from '@tvai/gatsby-plugin-i18next';
import Fade from 'react-reveal/Fade';

import FranchiseForm from '../../../forms/franchise';
import Title from '../../BannerShared/Title';

const FranchiseBottomForm = () => {
  const { t } = useTranslation();

  return (
    <div className="banner banner-bottom-form">
      <div className="container">
        <Fade>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Fade>
                <section>
                  <a id="franchise-form-anchor" />
                  <Title title={t('franchise_letsdothis_label')} isTagline isCenter />
                  <Title title={t(`franchise_letsdothis_title`)} />
                  <p>{t(`franchise_letsdothis_body`)}</p>
                </section>
              </Fade>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24}>
              <FranchiseForm />
            </Col>
          </Row>
        </Fade>
      </div>
    </div>
  );
};

FranchiseBottomForm.propTypes = {
  t: PropTypes.func,
};

export default FranchiseBottomForm;
