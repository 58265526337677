import '../banners.less';
import './index.less';

import { Link } from '@tvai/gatsby-plugin-i18next';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import Map from '../../../../static/svg/globalexpansion.svg';
import Breakpoint from '../../../utils/breakpoint';
import Title from '../../BannerShared/Title';
import Button from '../../Button';
import FranchiseMap from '../../FranchiseMap';

const Covid = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-globalexpansion">
      <div className="container">
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={18} xl={16}>
            <Fade>
              <section style={{ textAlign: 'center' }}>
                <Title title={t('franchise_globalexpansion_label')} isTagline isCenter />
                <Title
                  title={t('franchise_globalexpansion_title')}
                  description={t('franchise_globalexpansion_body')}
                  style={{ marginBottom: '50px' }}
                />
              </section>
            </Fade>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={24}>
            <FranchiseMap />
          </Col>
        </Row>
        <div className="buttons-container" style={{ textAlign: 'center', marginTop: '60px' }}>
          <a href="#franchise-form-anchor">
            <Button type="button">{t(`franchise_globalexpansion_button`).toLowerCase()}</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

Covid.propTypes = {
  t: PropTypes.func,
};

export default Covid;
