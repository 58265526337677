import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import GreyChevron from '../../../../static/svg/grey-chevron-left.svg';
import retentionImg from '../../../images/banners/retention.jpg';
import Breakpoint from '../../../utils/breakpoint';
import Title from '../../BannerShared/Title';

const RetentionRenewal = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-retention">
      <div className="container">
        <Row type="flex" align="middle">
          <Col xs={24} sm={24} md={24} lg={12}>
            <Fade>
              <div className="box-image-container">
                <Breakpoint max="sm">
                  <img src={GreyChevron} alt="grey chevron" className="left-chevron" />
                </Breakpoint>
                <Breakpoint min="sm">
                  <img src={GreyChevron} alt="grey chevron" className="right-chevron" />
                </Breakpoint>
                <div className="grey-box" />
                <img src={retentionImg} alt="The Protocols" />
              </div>
            </Fade>
          </Col>
          <Col xs={24} sm={24} md={22} lg={12}>
            <section>
              <Fade>
                <Title title={t('franchise_retention_label')} isTagline />
                <Title title={t(`franchise_retention_title`)} />
                <p>{t(`franchise_retention_body`)}</p>
              </Fade>
            </section>
          </Col>
        </Row>
      </div>
    </div>
  );
};

RetentionRenewal.propTypes = {
  t: PropTypes.func,
};

export default RetentionRenewal;
