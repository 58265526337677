import React from 'react';

import './index.less';

const Video = (props) => {
  const { src } = props;
  return <iframe title="Video" src={src} allowFullScreen></iframe>;
};

export default Video;
