import '../banners.less';
import './index.less';

import { Link } from '@tvai/gatsby-plugin-i18next';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import GIF from '../../../images/giftest.gif';
import FAQAccordion from '../../BannerShared/FAQAccordion';
import Title from '../../BannerShared/Title';
import AppButton from '../../Button';

const FAQShortlist = ({ location, countryCode = 'SG', hideButton = false, refProp, isEOYOffer = false }) => {
  const { t } = useTranslation();
  const FAQData = useMemo(() => {
    if (location === 'franchise') {
      return [
        {
          titleKey: 'franchise_faq_totalinvestment_title',
          bodyKey: 'franchise_faq_totalinvestment_desc',
        },
        {
          titleKey: 'franchise_faq_businessinbox_title',
          bodyKey: 'franchise_faq_businessinbox_desc',
        },
        {
          titleKey: 'franchise_faq_ongoingfees_title',
          bodyKey: 'franchise_faq_ongoingfees_desc',
        },
        {
          titleKey: 'franchise_faq_howmuchmoney_title',
          bodyKey: 'franchise_faq_howmuchmoney_desc',
        },
        {
          titleKey: 'franchise_faq_term_title',
          bodyKey: 'franchise_faq_term_desc',
        },
        {
          titleKey: 'franchise_faq_background_title',
          bodyKey: 'franchise_faq_background_desc',
        },
        {
          titleKey: 'franchise_faq_activelyinvolved_title',
          bodyKey: 'franchise_faq_activelyinvolved_desc',
        },
        {
          titleKey: 'franchise_faq_oss_title',
          bodyKey: 'franchise_faq_oss_desc',
        },
        {
          titleKey: 'franchise_faq_whychooseritual_title',
          bodyKey: 'franchise_faq_whychooseritual_desc',
        },
      ];
    }

    if (location === 'theworkout') {
      return [
        {
          titleKey: 'commonquestions_ReallyEnough_title',
          bodyKey: 'commonquestions_ReallyEnough_desc',
        },
        {
          titleKey: 'commonquestions_FundamentalMovements_title',
          bodyKey: 'commonquestions_FundamentalMovements_desc',
        },
        {
          titleKey: 'commonquestions_TrainingTough_title',
          bodyKey: 'commonquestions_TrainingTough_desc',
        },
        {
          titleKey: 'commonquestions_PushHard_title',
          bodyKey: 'commonquestions_PushHard_desc',
        },
        {
          titleKey: 'commonquestions_Calories_title',
          bodyKey: 'commonquestions_Calories_desc',
        },
      ];
    }

    if (location === 'trial' && (countryCode === 'SG' || countryCode === 'US' || countryCode === 'CH')) {
      return [
        {
          titleKey: 'commonquestions_WhoIsRitualFor_title',
          bodyKey: 'commonquestions_WhoIsRitualFor_desc',
        },
        {
          titleKey: 'commonquestions_OutOfShape_title',
          bodyKey: 'commonquestions_OutOfShape_desc',
        },
        {
          titleKey: 'commonquestions_WhatResults_title',
          bodyKey: 'commonquestions_WhatResults_desc',
        },
        {
          titleKey: 'commonquestions_TrialInclude_title',
          bodyKey: 'commonquestions_TrialInclude_desc',
        },
        {
          titleKey: 'faq-6-hiddencost-title',
          bodyKey: 'faq-6-hiddencost-description',
        },
        {
          titleKey: 'commonquestions_WhatIsIntroClass_title',
          bodyKey: 'commonquestions_WhatIsIntroClass_desc',
        },
        {
          titleKey: 'commonquestions_TrainMorePayLess_title',
          bodyKey: 'commonquestions_TrainMorePayLess_desc',
        },
        {
          titleKey: 'commonquestions_MedicalIssue_title',
          bodyKey: 'commonquestions_MedicalIssue_desc',
        },
      ];
    }

    if (isEOYOffer) {
      return [
        {
          titleKey: 'commonquestions_WhoIsRitualFor_title',
          bodyKey: 'commonquestions_WhoIsRitualFor_desc',
        },
        {
          titleKey: 'commonquestions_OutOfShape_title',
          bodyKey: 'commonquestions_OutOfShape_desc',
        },
        {
          titleKey: 'commonquestions_FirstVisit_title',
          bodyKey: 'commonquestions_FirstVisit_desc',
        },
        {
          titleKey: 'commonquestions_WhatResults_title',
          bodyKey: 'commonquestions_WhatResults_desc',
        },
        {
          titleKey: 'commonquestions_MedicalIssue_title',
          bodyKey: 'commonquestions_MedicalIssue_desc',
        },
      ];
    }

    return [
      {
        titleKey: 'commonquestions_WhoIsRitualFor_title',
        bodyKey: 'commonquestions_WhoIsRitualFor_desc',
      },
      {
        titleKey: 'commonquestions_OutOfShape_title',
        bodyKey: 'commonquestions_OutOfShape_desc',
      },
      {
        titleKey: 'commonquestions_TrialInclude_title',
        bodyKey: 'commonquestions_TrialInclude_desc',
      },
      {
        titleKey: 'commonquestions_FirstVisit_title',
        bodyKey: 'commonquestions_FirstVisit_desc',
      },
      {
        titleKey: 'commonquestions_WhatResults_title',
        bodyKey: 'commonquestions_WhatResults_desc',
      },
      {
        titleKey: 'commonquestions_PurchaseTrialLater_title',
        bodyKey: 'commonquestions_PurchaseTrialLater_desc',
      },
      {
        titleKey: 'commonquestions_MedicalIssue_title',
        bodyKey: 'commonquestions_MedicalIssue_desc',
      },
    ];
  }, [location, isEOYOffer, countryCode]);

  return (
    <div className="banner banner-faq-shortlist" ref={refProp}>
      <div className="container">
        <Row>
          <Col span={24}>
            <Fade>
              <section>
                <Title title={t('Website_Home_LearnMore')} isTagline isCenter />
                <Title title={t(`home_faq_title`)} />
                <FAQAccordion data={FAQData} />
              </section>
            </Fade>
            {!hideButton && (
              <div className="buttons-container">
                <Link to="/faq/">
                  <AppButton>{t(`home_faq_button_seeallfaq`).toLowerCase()}</AppButton>
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

FAQShortlist.propTypes = {
  location: PropTypes.string,
  countryCode: PropTypes.string,
  hideButton: PropTypes.bool,
  isEOYOffer: PropTypes.bool,
  refProp: PropTypes.object,
};

export default FAQShortlist;
