import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import GreyChevron from '../../../../static/svg/grey-chevron-left.svg';
import oursystemsImg from '../../../images/banners/oursystems.jpg';
import Title from '../../BannerShared/Title';

const OurSystems = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-oursystems">
      <div className="container">
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={12}>
            <Fade>
              <div className="box-image-container">
                <img src={GreyChevron} alt="grey chevron" className="right-chevron" />
                <div className="grey-box" />
                <img src={oursystemsImg} alt="The Protocols" />
              </div>
            </Fade>
          </Col>
          <Col xs={24} sm={24} md={22} lg={12}>
            <section>
              <Fade>
                <Title title={t('franchise_oursystems_label')} isTagline />
                <Title title={t(`franchise_oursystems_title`)} />
                <p>{t(`franchise_oursystems_body`)}</p>
                <ul className="red-tick-list">
                  <li>
                    <strong>{t(`franchise_oursystems_list1_a`)} </strong>
                    {t(`franchise_oursystems_list1_b`)}
                  </li>
                  <li>
                    <strong>{t(`franchise_oursystems_list2_a`)} </strong>
                    {t(`franchise_oursystems_list2_b`)}
                  </li>
                  <li>
                    <strong>{t(`franchise_oursystems_list3_a`)} </strong>
                    {t(`franchise_oursystems_list3_b`)}
                  </li>
                </ul>
              </Fade>
            </section>
          </Col>
        </Row>
      </div>
    </div>
  );
};

OurSystems.propTypes = {
  t: PropTypes.func,
};

export default OurSystems;
