import { withI18next } from '@tvai/gatsby-plugin-i18next';

import { Modal } from 'antd';
import { graphql } from 'gatsby';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import FAQShortlist from '../components/Banners/FAQShortlist';
import HeroFranchise from '../components/Banners/Franchise';
import FranchiseBottomForm from '../components/Banners/FranchiseBottomForm';
import FranchiseeSupport from '../components/Banners/FranchiseeSupport';
import FranchiseTopForm from '../components/Banners/FranchiseTopForm';
import GlobalExpansion from '../components/Banners/GlobalExpansion';
import IncentiveProgram from '../components/Banners/IncentiveProgram';
import KeepInTouch from '../components/Banners/KeepInTouch';
import OurSystems from '../components/Banners/OurSystems';
import RetentionRenewal from '../components/Banners/RetentionRenewal';
import TheFacility from '../components/Banners/TheFacility';
import TheInvestment from '../components/Banners/TheInvestment';
import WhyRitual from '../components/Banners/WhyRitual';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Video from '../components/Video';
import { SetupInfoProvider } from '../providers/setupInfoProvider';
import { checkLanguage } from '../utils/language';

class Page extends Component {
  state = {
    showVideo: false,
  };

  componentDidMount() {
    checkLanguage(this.props.pageContext, this.props.location);
  }

  handleVideo = () => {
    this.setState({
      showVideo: !this.state.showVideo,
    });
  };

  render() {
    const { t } = this.props;
    const { showVideo } = this.state;

    return (
      <SetupInfoProvider>
        <Layout navInvert disclaimer>
          <SEO pageTitle={t(`meta_title_franchise`)} />

          {showVideo ? (
            <Modal
              maskStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              }}
              zIndex={9999999}
              maskClosable
              visible
              onCancel={this.handleVideo}
              centered
              wrapClassName="modal-video"
              footer={null}
            >
              <Video src="https://www.youtube.com/embed/wQ7GsR4sLrU" />
            </Modal>
          ) : null}

          <HeroFranchise handleVideo={this.handleVideo} />
          <FranchiseTopForm />

          <WhyRitual />
          <FranchiseeSupport />
          <OurSystems />
          <TheFacility />
          <GlobalExpansion />
          <RetentionRenewal />
          <TheInvestment />
          <FAQShortlist location="franchise" />
          <IncentiveProgram />
          <FranchiseBottomForm />
          <KeepInTouch />
        </Layout>
      </SetupInfoProvider>
    );
  }
}

export default withI18next()(withTranslation()(Page));

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;
