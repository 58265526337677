import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Link } from '@tvai/gatsby-plugin-i18next';
import Fade from 'react-reveal/Fade';

import FranchiseForm from '../../../forms/franchise';
import LogoTriangleBlack from '../../../images/logo-triangle-black.png';
import Title from '../../BannerShared/Title';

const FranchiseTopForm = () => {
  const { t } = useTranslation();

  return (
    <div className="banner banner-top-form">
      <div className="container">
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={20} xl={16}>
            <Fade>
              <section style={{ textAlign: 'center' }}>
                <img
                  src={LogoTriangleBlack}
                  alt="Triangle"
                  style={{ width: '100px', marginTop: '30px', marginBottom: '30px' }}
                />
                <Title title={t('franchise_opportunity_tagline')} isTagline isCenter />
                <h2>{t('franchise_opportunity_title')}</h2>
                <p style={{ maxWidth: '600px', margin: '0px auto', marginBottom: '0px' }}>
                  {t('franchise_opportunity_desc')}
                </p>
              </section>
            </Fade>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={24}>
            <FranchiseForm />
          </Col>
        </Row>
      </div>
    </div>
  );
};

FranchiseTopForm.propTypes = {
  t: PropTypes.func,
};

export default FranchiseTopForm;
