import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import USP1 from '../../../../static/svg/franchisee_support_usp1.svg';
import USP2 from '../../../../static/svg/franchisee_support_usp2.svg';
import USP3 from '../../../../static/svg/franchisee_support_usp3.svg';
import USP4 from '../../../../static/svg/franchisee_support_usp4.svg';
import USP5 from '../../../../static/svg/franchisee_support_usp5.svg';
import USP6 from '../../../../static/svg/franchisee_support_usp6.svg';
import Breakpoint from '../../../utils/breakpoint';
import Title from '../../BannerShared/Title';

const FranchiseeSupport = () => {
  const { t } = useTranslation();

  return (
    <div className="banner banner-franchiseesupport">
      <div className="container">
        <Breakpoint min="sm">
          <div className="ksp-wrapper">
            <div className="ksp-bg">{t('franchise_support_label')}</div>
          </div>
        </Breakpoint>
        <Fade>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <section style={{ marginBottom: '40px' }}>
                <Row gutter={40} justify="space-between">
                  <Col xs={24} sm={24} md={24} lg={10}>
                    <Breakpoint max="md">
                      <Title title={t('franchise_support_label')} isTagline />
                    </Breakpoint>
                    <Title title={t('franchise_support_title')} titleClassName="ksp-hashtag" />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <p className="ksp-p">{t(`franchise_support_body`)}</p>
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Fade>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="franchiseesupport-usp">
              <img src={USP1} alt="USP1" />
              <h1>{t('franchise_support_usp1_title')}</h1>
              <p>{t('franchise_support_usp1_body')}</p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="franchiseesupport-usp">
              <img src={USP2} alt="USP2" />
              <h1>{t('franchise_support_usp2_title')}</h1>
              <p>{t('franchise_support_usp2_body')}</p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="franchiseesupport-usp">
              <img src={USP3} alt="USP3" />
              <h1>{t('franchise_support_usp3_title')}</h1>
              <p>{t('franchise_support_usp3_body')}</p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="franchiseesupport-usp">
              <img src={USP4} alt="USP4" />
              <h1>{t('franchise_support_usp4_title')}</h1>
              <p>{t('franchise_support_usp4_body')}</p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="franchiseesupport-usp">
              <img src={USP5} alt="USP5" />
              <h1>{t('franchise_support_usp5_title')}</h1>
              <p>{t('franchise_support_usp5_body')}</p>
            </div>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="franchiseesupport-usp">
              <img src={USP6} alt="USP6" />
              <h1>{t('franchise_support_usp6_title')}</h1>
              <p>{t('franchise_support_usp6_body')}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

FranchiseeSupport.propTypes = {
  t: PropTypes.func,
};

export default FranchiseeSupport;
